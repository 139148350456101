import styled from 'styled-components';
import { Columns } from '../../sdk/Layout';

export const Image = styled.figure`
    margin: auto;
  img {
    width: 100%;
    border-radius: ${(props) => props.theme.radio};
  }

`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .inner {
    .title {
      position: relative;
      margin-right: 30px;
      margin-bottom: 30px;
      max-width: 600px;
      .icon-corner {
        position: absolute;
        top: -40px;
        right: -20px;
        color: ${(props) => props.theme.primary};
        width: 80px;
        height: 80px;
      }
    }
    h2 {
        font-size: 60px;
        line-height: 1.2;
        text-transform: uppercase;
        font-weight: 800;
        color: ${(props) => props.theme.black};
    }
    p {
        margin: 20px 0 30px 0;
        font-size: 18px;
        line-height: 28px;
        color: ${(props) => props.theme.gray};
    }
  }

  @media (max-width: 1366px) {
    .inner {
      .title {
        max-width: 500px;
      }
      h2 {
        font-size: 48px;
      }
    }
  }

  @media (max-width: 1044px) {
    .inner {
      .title {
        max-width: 400px;
      }
      h2 {
        font-size: 32px;
      }
    }
  }

  @media (max-width: 768px) {
    text-align: center;
    .inner {
        .title {
          max-width: 100%;
          margin: 20px 0;
          .icon-corner {
            display: none;
          }
        }
        h2 {
            font-size: 28px;
        }
        p {
            font-size: 16px;
            line-height: 22px;
        }
        .button-blue {
            display: block;
            display: flex;
            justify-content: center;
        }
    }
  }
`;

export const PrivateLabel = styled.div`
  display: flex;  
  gap: 40px;
  > div {
    width: 50%;
  }
  @media (max-width: 768px) {
    > div {
      width: 100%;
    }
    gap: 20px;
    flex-direction: column-reverse;
  }
`;
