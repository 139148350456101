import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Image, Text, PrivateLabel } from "./style";
import InternalLink from "../InternalLink";
import Icons from "../Icons2";
import { Button } from "../../sdk/Button";
import { Container, Section, Columns, Column } from "../../sdk/Layout";

function CTAPrivateLabel({ config }) {
    if (config.length === 0) return null;

    return (
        <Section>
            <Container large>
                    <PrivateLabel>
                        <div>
                            <Image>
                                <LazyLoadImage
                                    // src={config[0].image.img}
                                    src="https://www.noritex.com/uplds/dev/bPuJXTHHsraYPfmJmmarca_privada-min.jpg"
                                    alt={config[0].title}
                                />
                            </Image>
                        </div>
                        <div>
                            <Text>
                                <div className="inner">
                                    <div className="title">
                                        <h2>{config[0].title}</h2>
                                        <Icons icon="ntx-corner-rt" className="icon-corner" />
                                    </div>
                                    {/* <p>{config[0].content}</p> */}
                                    {config[0].link ? (
                                        <InternalLink
                                            {...config[0].link}
                                            className="button-blue"
                                        >
                                            <Button primary large>
                                                {config[0].link.title}
                                            </Button>
                                        </InternalLink>
                                    ) : null}
                                </div>
                            </Text>
                        </div>
                    </PrivateLabel>
            </Container>
        </Section>
    );
}

export default CTAPrivateLabel;
